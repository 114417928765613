h1 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 800;
  margin-bottom: 12px;
}
h2 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 12px;
}
h3 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
  font-weight: 500;
}
h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 12px;
}
h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 8px;
}
h6 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 4px;
}
p,
.p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 8px;
}
.view-title {
  font-size: 36px;
  line-height: 40px;
  font-weight: 800;
}
.section-title {
  font-size: 18px;
  line-height: 24px;
  color: #00529b;
  font-weight: 700;
}
.message {
  color: #7e7e7e;
}
.primary {
  color: #00529b;
}
.success {
  color: #5ad25c;
}
.bold {
  font-weight: 600;
}
.extra-bold {
  font-weight: 700;
}
.text-center {
  text-align: center;
}
.Back {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.Back .Icon {
  width: 12px;
  height: 12px;
  transform: rotate(180deg);
  margin-right: 4px;
}
.badge {
  border-radius: 16px;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-weight: 600;
}
.badge.neutral {
  background: rgba(255,170,0,0.1);
  border: 1px solid #fa0;
  color: #fa0;
}
.badge.success {
  background: rgba(35,196,140,0.1);
  border: 1px solid #23c48c;
  color: #23c48c;
}
.badge.danger {
  background: rgba(237,53,18,0.1);
  border: 1px solid #ed3512;
  color: #ed3512;
}
.button {
  display: flex;
  justify-content: center;
  background-color: #00529b;
  color: #fff;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  border: 0px;
  border: 3px solid transparent;
  width: 100%;
}
.button:focus {
  outline: none;
}
.button:hover {
  background-color: #004a8b;
  cursor: pointer;
}
.button:disabled {
  background: #004a8b;
}
.button.disabled,
.button.inactive {
  background: rgba(0,82,155,0.75);
}
.button.disabled {
  pointer-events: none;
}
.button.inverted {
  border-color: #00529b;
  color: #00529b;
  background: #fff;
}
.button.inverted:hover {
  color: #fff;
  background-color: #004a8b;
  cursor: pointer;
}
.button.inverted.fill {
  background-color: #f5f5f5;
  border-color: transparent;
}
.button.inverted.fill:hover {
  color: #00529b;
}
.button.danger {
  background-color: #ed3512;
  color: #fff;
}
.button.danger.inverted {
  background-color: #f5f5f5;
  color: #ed3512;
  border-color: transparent;
}
.button.danger:disabled.inverted {
  background-color: #f5f5f5;
  color: rgba(237,53,18,0.4);
}
.button.short {
  padding: 8px 24px;
}
.button.tall {
  padding: 12px 24px;
}
.button.small {
  padding: 4px 12px;
  font-size: 16px;
}
.button.center :first-child {
  justify-content: center;
}
.button :last-child {
  display: flex;
  justify-content: flex-end;
}
.button :first-child {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.button .Icon {
  width: 24px;
  height: 24px;
}
.icon-button {
  color: #00529b;
}
.icon-button:hover {
  color: #00529b;
  cursor: pointer;
}
.button-explination {
  color: #7e7e7e;
  font-size: 11px;
  line-height: 15px;
  margin-top: 8px;
  text-align: center;
}
.button-explination.top {
  margin-top: 0px;
  margin-bottom: 12px;
}
.Card {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(3,83,136,0.1);
  border-radius: 4px;
  padding: 16px 20px;
  min-height: 84px;
  color: #3b3b3b;
  cursor: pointer;
}
.Card > :nth-child(1) {
  margin-right: 20px;
}
.Card > :nth-child(2),
.Card .name {
  flex: 1;
  font-size: 20px;
  line-height: 24px;
  color: #00529b;
}
.Card > :nth-child(2).direction-icon,
.Card .name.direction-icon {
  flex: 0 1 auto;
}
.Card .Icon {
  color: #00529b;
  width: 36px;
  height: 36px;
}
.Card .direction-icon {
  display: flex;
}
.Card .direction-icon .Icon {
  width: 16px;
  height: 16px;
}
.section .Card {
  margin-bottom: 20px;
}
.section .Card:last-child {
  margin-bottom: 0px;
}
.FallbackCard {
  text-align: center;
  padding: 32px 20px;
  box-shadow: 0px 0px 10px rgba(3,83,136,0.1);
  color: #dcdcdc;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FallbackCard .Icon {
  width: 40px;
  margin-bottom: 12px;
}
.FallbackCard .link {
  margin-top: 12px;
}
* {
  box-sizing: border-box;
  margin: 0;
}
body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}
html,
body,
#root {
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
}
.App > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
a {
  text-decoration: none;
}
input,
textarea {
  outline: none;
  border: none;
}
textarea {
  resize: vertical;
  font-family: 'Open Sans', sans-serif;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
}
.Icon.fill {
  fill: currentColor;
}
.Icon.stroke {
  stroke: currentColor;
}
.Icon.fill-none {
  fill: none;
}
.Icon path.fill.primary,
.Icon circle.fill.primary,
.Icon rect.fill.primary {
  fill: #00529b;
}
.Icon path.fill.secondary,
.Icon circle.fill.secondary,
.Icon rect.fill.secondary {
  fill: #23c48c;
}
.Icon path.fill.tertiary,
.Icon circle.fill.tertiary,
.Icon rect.fill.tertiary {
  fill: #ed3512;
}
.Icon path.stroke.primary,
.Icon circle.stroke.primary,
.Icon rect.stroke.primary {
  stroke: #00529b;
}
.Icon path.stroke.secondary,
.Icon circle.stroke.secondary,
.Icon rect.stroke.secondary {
  stroke: #23c48c;
}
.Icon path.stroke.tertiary,
.Icon circle.stroke.tertiary,
.Icon rect.stroke.tertiary {
  stroke: #ed3512;
}
.link {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #00529b;
  display: inline-flex;
}
.link:hover {
  cursor: pointer;
  color: #004a8b;
}
.link.secondary {
  color: #7e7e7e;
}
.link.secondary:hover {
  color: #656565;
}
.link.danger {
  color: #ed3512;
}
.link.danger:hover {
  color: #be2a0e;
}
.link.medium {
  font-size: 15px;
  line-height: 18px;
}
.link.small {
  font-size: 12px;
  line-height: 16px;
}
.link.x-small {
  font-size: 9px;
  line-height: 12px;
}
.link-wrapper {
  display: flex;
  justify-content: center;
}
.LoadingSpinner.loading-spinner-container {
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.LoadingSpinner.loading-spinner-container .loading-spinner {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0.25rem solid #f9f9f9;
  border-top-color: #00529b;
  -webkit-animation: spin 0.85s infinite linear;
  animation: spin 0.85s infinite linear;
}
.LoadingSpinner.small {
  min-height: auto;
}
.LoadingSpinner.small .loading-spinner {
  width: 20px;
  height: 20px;
}
.LoadingSpinner.light .loading-spinner {
  border-top-color: #dcdcdc;
}
@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 24px;
  border: 0px;
  border-radius: 2px;
  max-width: 340px;
  width: 100%;
  max-height: 90%;
  overflow-y: auto;
}
.Modal.review-modal {
  max-width: 720px;
  height: max-content;
}
@media screen and (max-width: 764px) {
  .Modal.review-modal {
    max-width: 90%;
  }
}
.Modal.sticky-footer {
  padding: 24px 24px 0px;
}
.Modal:focus {
  outline: none;
}
.Modal .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  color: #00529b;
  font-weight: 600;
  font-size: 18px;
}
.Modal .header .Icon {
  width: 12px;
  color: #7e7e7e;
}
.Modal .header .Icon:hover {
  cursor: pointer;
  color: #3b3b3b;
}
.Modal .body h4 {
  font-weight: 700;
  margin-bottom: 12px;
}
.Modal .body h5 {
  margin-bottom: 8px;
}
.Modal .body p {
  margin-bottom: 12px;
  font-size: 13px;
  line-height: 17px;
}
.Modal .body p:last-child {
  margin-bottom: 0px;
}
.Modal .body .bold {
  font-weight: 700;
}
.Modal .body .underline {
  text-decoration: underline;
}
.Modal .body .confirm-message {
  color: #7e7e7e;
}
.Modal .body .gray-paragraph {
  background-color: #f2f2f2;
}
.Modal .body .policy-header {
  text-align: center;
}
.Modal .footer.sticky {
  position: sticky;
  bottom: 0;
  width: calc(100% + (24px * 2));
  background: #fff;
  padding: 24px 24px;
  border-top: 1px solid #f2f2f2;
  margin-left: -24px;
  z-index: 2;
}
.Modal.confirm-modal .header {
  font-size: 20px;
  font-weight: 700;
}
.Modal.confirm-modal .body {
  margin: 16px 0 32px;
}
.Modal.confirm-modal .footer {
  display: flex;
  justify-content: flex-end;
}
.Modal.confirm-modal .footer .button {
  display: inline-flex;
  width: auto;
  min-width: 100px;
  min-height: 38px;
}
.ReactModal__Body--open {
  overflow-y: hidden !important;
}
.ReactModal__Overlay {
  background: rgba(0,0,0,0.8) !important;
  z-index: 3;
}
.NavBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 0px 20px;
}
.NavBar .date-greeting .date {
  font-size: 14px;
  color: #7e7e7e;
  margin-bottom: 4px;
}
.NavBar .settings {
  width: 16px;
  margin-top: 4px;
}
.policy-text {
  font-size: 14px !important;
  line-height: 23px !important;
  color: #7e7e7e !important;
  margin-left: 4px;
}
.Policy .lst-kix_ocsecbsqknzn-7 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_ocsecbsqknzn-8 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_ocsecbsqknzn-5 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_ocsecbsqknzn-3 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_ocsecbsqknzn-4 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_6my9y4cmtp5r-1 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_ocsecbsqknzn-2 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_6my9y4cmtp5r-0 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_6my9y4cmtp5r-5 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_6my9y4cmtp5r-7 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_6my9y4cmtp5r-2 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_6my9y4cmtp5r-6 > li:before {
  content: "\0025cf  ";
}
.Policy ul.lst-kix_w83b74ayvp9z-0 {
  list-style-type: none;
}
.Policy ul.lst-kix_ocsecbsqknzn-3 {
  list-style-type: none;
}
.Policy ul.lst-kix_ocsecbsqknzn-4 {
  list-style-type: none;
}
.Policy ul.lst-kix_ocsecbsqknzn-5 {
  list-style-type: none;
}
.Policy ul.lst-kix_ocsecbsqknzn-6 {
  list-style-type: none;
}
.Policy ul.lst-kix_ocsecbsqknzn-0 {
  list-style-type: none;
}
.Policy ul.lst-kix_ocsecbsqknzn-1 {
  list-style-type: none;
}
.Policy ul.lst-kix_ocsecbsqknzn-2 {
  list-style-type: none;
}
.Policy ul.lst-kix_w83b74ayvp9z-7 {
  list-style-type: none;
}
.Policy ul.lst-kix_w83b74ayvp9z-8 {
  list-style-type: none;
}
.Policy ul.lst-kix_w83b74ayvp9z-5 {
  list-style-type: none;
}
.Policy ul.lst-kix_w83b74ayvp9z-6 {
  list-style-type: none;
}
.Policy ul.lst-kix_w83b74ayvp9z-3 {
  list-style-type: none;
}
.Policy ul.lst-kix_w83b74ayvp9z-4 {
  list-style-type: none;
}
.Policy ul.lst-kix_w83b74ayvp9z-1 {
  list-style-type: none;
}
.Policy ul.lst-kix_w83b74ayvp9z-2 {
  list-style-type: none;
}
.Policy ul.lst-kix_yo71qc2kshxw-5 {
  list-style-type: none;
}
.Policy ul.lst-kix_yo71qc2kshxw-4 {
  list-style-type: none;
}
.Policy ul.lst-kix_yo71qc2kshxw-3 {
  list-style-type: none;
}
.Policy ul.lst-kix_yo71qc2kshxw-2 {
  list-style-type: none;
}
.Policy ul.lst-kix_yo71qc2kshxw-8 {
  list-style-type: none;
}
.Policy ul.lst-kix_yo71qc2kshxw-7 {
  list-style-type: none;
}
.Policy ul.lst-kix_yo71qc2kshxw-6 {
  list-style-type: none;
}
.Policy ul.lst-kix_yo71qc2kshxw-1 {
  list-style-type: none;
}
.Policy ul.lst-kix_yo71qc2kshxw-0 {
  list-style-type: none;
}
.Policy ul.lst-kix_ocsecbsqknzn-7 {
  list-style-type: none;
}
.Policy ul.lst-kix_ocsecbsqknzn-8 {
  list-style-type: none;
}
.Policy ul.lst-kix_l31fmbsmcfoj-8 {
  list-style-type: none;
}
.Policy ul.lst-kix_pfuljev6iu9o-5 {
  list-style-type: none;
}
.Policy ul.lst-kix_pfuljev6iu9o-6 {
  list-style-type: none;
}
.Policy ul.lst-kix_pfuljev6iu9o-3 {
  list-style-type: none;
}
.Policy ul.lst-kix_pfuljev6iu9o-4 {
  list-style-type: none;
}
.Policy ul.lst-kix_pfuljev6iu9o-1 {
  list-style-type: none;
}
.Policy ul.lst-kix_pfuljev6iu9o-2 {
  list-style-type: none;
}
.Policy ul.lst-kix_pfuljev6iu9o-0 {
  list-style-type: none;
}
.Policy ul.lst-kix_pfuljev6iu9o-7 {
  list-style-type: none;
}
.Policy ul.lst-kix_pfuljev6iu9o-8 {
  list-style-type: none;
}
.Policy ul.lst-kix_l31fmbsmcfoj-2 {
  list-style-type: none;
}
.Policy ul.lst-kix_l31fmbsmcfoj-3 {
  list-style-type: none;
}
.Policy ul.lst-kix_l31fmbsmcfoj-0 {
  list-style-type: none;
}
.Policy ul.lst-kix_l31fmbsmcfoj-1 {
  list-style-type: none;
}
.Policy ul.lst-kix_l31fmbsmcfoj-6 {
  list-style-type: none;
}
.Policy ul.lst-kix_l31fmbsmcfoj-7 {
  list-style-type: none;
}
.Policy ul.lst-kix_l31fmbsmcfoj-4 {
  list-style-type: none;
}
.Policy ul.lst-kix_l31fmbsmcfoj-5 {
  list-style-type: none;
}
.Policy ul.lst-kix_eznulzp42u4v-7 {
  list-style-type: none;
}
.Policy ul.lst-kix_eznulzp42u4v-6 {
  list-style-type: none;
}
.Policy ul.lst-kix_eznulzp42u4v-8 {
  list-style-type: none;
}
.Policy ul.lst-kix_eznulzp42u4v-1 {
  list-style-type: none;
}
.Policy ul.lst-kix_eznulzp42u4v-0 {
  list-style-type: none;
}
.Policy ul.lst-kix_eznulzp42u4v-3 {
  list-style-type: none;
}
.Policy ul.lst-kix_eznulzp42u4v-2 {
  list-style-type: none;
}
.Policy ul.lst-kix_eznulzp42u4v-5 {
  list-style-type: none;
}
.Policy ul.lst-kix_eznulzp42u4v-4 {
  list-style-type: none;
}
.Policy ul.lst-kix_6my9y4cmtp5r-0 {
  list-style-type: none;
}
.Policy ul.lst-kix_6my9y4cmtp5r-1 {
  list-style-type: none;
}
.Policy ul.lst-kix_6my9y4cmtp5r-2 {
  list-style-type: none;
}
.Policy ul.lst-kix_6my9y4cmtp5r-3 {
  list-style-type: none;
}
.Policy ul.lst-kix_6my9y4cmtp5r-8 {
  list-style-type: none;
}
.Policy ul.lst-kix_6my9y4cmtp5r-4 {
  list-style-type: none;
}
.Policy ul.lst-kix_6my9y4cmtp5r-5 {
  list-style-type: none;
}
.Policy ul.lst-kix_6my9y4cmtp5r-6 {
  list-style-type: none;
}
.Policy ul.lst-kix_6my9y4cmtp5r-7 {
  list-style-type: none;
}
.Policy .lst-kix_6my9y4cmtp5r-3 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_ocsecbsqknzn-6 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_6my9y4cmtp5r-4 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_w83b74ayvp9z-7 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_w83b74ayvp9z-6 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_w83b74ayvp9z-8 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_w83b74ayvp9z-5 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_ocsecbsqknzn-0 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_w83b74ayvp9z-0 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_eznulzp42u4v-1 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_w83b74ayvp9z-1 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_ocsecbsqknzn-1 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_eznulzp42u4v-0 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_w83b74ayvp9z-3 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_w83b74ayvp9z-2 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_w83b74ayvp9z-4 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_eznulzp42u4v-3 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_eznulzp42u4v-2 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_pfuljev6iu9o-5 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_pfuljev6iu9o-4 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_pfuljev6iu9o-2 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_pfuljev6iu9o-3 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_6my9y4cmtp5r-8 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_pfuljev6iu9o-0 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_pfuljev6iu9o-1 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_yo71qc2kshxw-0 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_pfuljev6iu9o-6 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_pfuljev6iu9o-7 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_pfuljev6iu9o-8 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_l31fmbsmcfoj-6 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_l31fmbsmcfoj-5 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_l31fmbsmcfoj-2 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_l31fmbsmcfoj-4 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_l31fmbsmcfoj-3 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_l31fmbsmcfoj-0 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_l31fmbsmcfoj-1 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_eznulzp42u4v-7 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_eznulzp42u4v-4 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_eznulzp42u4v-8 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_eznulzp42u4v-5 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_eznulzp42u4v-6 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_l31fmbsmcfoj-7 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_l31fmbsmcfoj-8 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_yo71qc2kshxw-1 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_yo71qc2kshxw-2 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_yo71qc2kshxw-3 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_yo71qc2kshxw-5 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_yo71qc2kshxw-4 > li:before {
  content: "\0025cb  ";
}
.Policy .lst-kix_yo71qc2kshxw-6 > li:before {
  content: "\0025cf  ";
}
.Policy .lst-kix_yo71qc2kshxw-8 > li:before {
  content: "\0025a0  ";
}
.Policy .lst-kix_yo71qc2kshxw-7 > li:before {
  content: "\0025cb  ";
}
.Policy ol {
  margin: 0;
  padding: 0;
}
.Policy table td,
.Policy table th {
  padding: 0;
}
.Policy .c2 {
  background-color: #fff;
  margin: 0 12pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.Policy .c4 {
  -webkit-text-decoration-skip: none;
  color: #000;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 10pt;
  font-family: "Arial";
  font-style: normal;
}
.Policy .c0 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Arial";
  font-style: normal;
}
.Policy .c1 {
  background-color: #fff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.Policy .c6 {
  background-color: #fff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.Policy .c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.Policy .c11 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
}
.Policy .c15 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #15c;
  text-decoration: underline;
}
.Policy .c14 {
  background-color: #fff;
  max-width: 468pt;
  padding: 0pt;
}
.Policy .c3 {
  padding: 0;
  margin: 0;
}
.Policy .c13 {
  font-size: 11pt;
  font-style: normal;
}
.Policy .c8 {
  color: inherit;
  text-decoration: inherit;
}
.Policy .c9 {
  font-size: 10pt;
}
.Policy .c16 {
  font-weight: 700;
}
.Policy .c10 {
  font-style: italic;
}
.Policy .c12 {
  vertical-align: super;
}
.Policy .c5 {
  height: 11pt;
}
.Policy .title {
  padding-top: 0pt;
  color: #000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.Policy .subtitle {
  padding-top: 0pt;
  color: #666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.Policy li {
  color: #000;
  font-size: 11pt;
  font-family: "Arial";
}
.Policy p {
  margin: 0;
  color: #000;
  font-size: 11pt;
  font-family: "Arial";
}
.Policy h1 {
  padding-top: 20pt;
  color: #000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.Policy h2 {
  padding-top: 18pt;
  color: #000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.Policy h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.Policy h4 {
  padding-top: 14pt;
  color: #666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.Policy h5 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.Policy h6 {
  padding-top: 12pt;
  color: #666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.ToolTip {
  cursor: pointer;
  z-index: 4;
}
.ToolTip .tooltip-text-wrapper {
  display: flex;
  align-items: center;
}
.ToolTip .tooltip-text-wrapper .tooltip-text {
  margin-right: 4px;
  line-height: 16px;
}
.ToolTip .tooltip-text-wrapper .Icon {
  width: 12px;
  height: 12px;
}
.ToolTip .tooltip-popup {
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(3,83,136,0.3);
  width: 100%;
  min-width: 200px;
  bottom: 100%;
  right: -8px;
  border-radius: 10px;
  padding: 8px 12px;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
  transition: visibility 0.125s, opacity 0.125s linear;
  transition-delay: 0.1s;
  text-align: left;
  cursor: text;
  text-transform: initial;
  color: #00529b;
  font-weight: 600;
}
.ToolTip .tooltip-popup.show {
  visibility: visible;
  opacity: 1;
  z-index: 2;
}
.ToolTip .tooltip-popup:before {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  position: absolute;
  bottom: -6px;
  right: 20px;
  margin: 0 auto;
}
.View {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  min-height: calc(var(--vh, 1vh) * 100);
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
}
.View.relative {
  position: relative;
}
.View.cover {
  position: absolute;
  padding: 0px;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  min-height: auto;
}
.View .section {
  margin-bottom: 24px;
}
.View .section.header {
  margin-top: 32px;
  margin-bottom: 8px;
}
.View .section.tight {
  margin-bottom: 12px;
}
.View .section.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.View .section.fixed,
.View .section .fixed {
  position: sticky;
  bottom: 0px;
  width: calc(100% + (24px * 2));
  background: #fff;
  padding: 24px 24px;
  border-top: 1px solid #f2f2f2;
  margin-left: -24px;
  z-index: 2;
  margin-bottom: 0px;
}
.View .section .section-title {
  margin-bottom: 24px;
}
.View .section .section-title.small-margin {
  margin-bottom: 12px;
}
.View .section.bottom {
  display: flex;
  align-items: flex-end;
  padding-bottom: 24px;
  margin-bottom: 0px;
}
.form-section.header {
  margin-top: 24px;
  margin-bottom: 24px;
}
.section.footer,
.form-section.footer,
.inner-section.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section.footer > :last-child,
.form-section.footer > :last-child,
.inner-section.footer > :last-child {
  margin-top: 8px;
}
.section.footer :first-child,
.form-section.footer :first-child,
.inner-section.footer :first-child {
  margin-top: 0px;
}
.columns {
  display: flex;
  flex-direction: column;
}
.stretch {
  flex: 1;
}
.full-height-test {
  background: #f00;
}
.Wizard .footer .hidden {
  visibility: hidden;
}
.ProgressBar {
  position: relative;
  height: 5px;
  width: 100%;
  background: #f5f5f5;
}
.ProgressBar .progress {
  background: #00529b;
  position: absolute;
  height: 100%;
}
.Logo {
  margin: 32px 0px 12px;
}
.Logo .Icon.symbol {
  width: 40px;
  margin-right: 20px;
}
.Logo .Icon.text {
  width: 180px;
}
.CheckAll.read-only .Choices {
  display: none;
}
.CheckAll {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #dcdcdc;
}
.CheckAll .Choices {
  display: flex;
  margin-top: 12px;
}
.CheckAll .Choices .ChoiceInput {
  display: flex;
}
.CheckAll .Choices .ChoiceInput:first-child {
  margin-right: 16px;
}
.CheckAll .Choices .ChoiceInput .label-container {
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: #00529b;
  text-transform: uppercase;
  font-size: 13px;
}
.CheckAll .Choices .ChoiceInput input {
  display: none;
}
.CheckAll .Choices .ChoiceInput .pseudo-input-container {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #00529b;
  border-radius: 2px;
}
.CheckAll .Choices .ChoiceInput input:checked ~ .pseudo-input-container {
  background: #00529b;
}
.CheckAll .Choices .ChoiceInput input:checked ~ .pseudo-input-container:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.Field:not(.inline) .Choices {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(95px, 95px));
  grid-auto-rows: 1fr;
  grid-gap: 16px;
  justify-content: center;
}
.Field:not(.inline) .label-and-field .input-wrapper {
  display: flex;
  flex-direction: column;
}
.Field:not(.inline) .label-and-field .input-wrapper .error {
  right: 4px;
}
.Field:not(.inline) .ChoiceInput {
  position: relative;
}
.Field:not(.inline) .ChoiceInput .pseudo-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 40px;
  border: 2px solid transparent;
  border-radius: 3px;
  padding: 12px 4px;
  user-select: none;
  box-shadow: 0px 0px 10px rgba(3,83,136,0.1);
}
.Field:not(.inline) .ChoiceInput .pseudo-input-container:hover {
  cursor: pointer;
}
.Field:not(.inline) .ChoiceInput input {
  display: none;
}
.Field:not(.inline) .ChoiceInput input:checked ~ .pseudo-input-container {
  border-color: #00529b;
}
.Field:not(.inline) .ChoiceInput .icon-container {
  width: 36px;
  margin-bottom: 8px;
}
.Field:not(.inline) .ChoiceInput .icon-container .Icon {
  height: 100%;
  width: 100%;
}
.Field:not(.inline) .ChoiceInput .required {
  position: absolute;
  color: #ed3512;
  right: 4px;
  top: 4px;
  font-size: 12px;
}
.Field:not(.inline) .ChoiceInput .label-container {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #7e7e7e;
  color: #00529b;
}
.Field:not(.inline) .ChoiceInput .label-container .tool-tip-container {
  position: absolute;
  top: 3px;
  right: 3px;
}
.Field:not(.inline) .ChoiceInput .label-container .label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical /* TODO: this line isn't included in the CSS for for some reason, added inline */;
  -webkit-line-clamp: 2;
}
.Field.inline .label-and-field {
  display: flex;
  align-items: center;
}
.Field.inline .label-and-field .error {
  right: 0;
  margin-left: 4px;
  top: -15px;
  font-size: 9px;
  pointer-events: none;
}
.Field.inline .Choices {
  display: flex;
  margin-left: 20px;
}
.Field.inline .Choices .ChoiceInput {
  display: flex;
  margin-right: 16px;
}
.Field.inline .Choices .ChoiceInput:last-child {
  margin-right: 0px;
}
.Field.inline .Choices .ChoiceInput .label-container {
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: #00529b;
  text-transform: uppercase;
  font-size: 13px;
}
.Field.inline .Choices .ChoiceInput input {
  display: none;
}
.Field.inline .Choices .ChoiceInput .pseudo-input-container {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #00529b;
  border-radius: 2px;
}
.Field.inline .Choices .ChoiceInput input:checked ~ .pseudo-input-container {
  background: #00529b;
}
.Field.inline .Choices .ChoiceInput input:checked ~ .pseudo-input-container:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.Fields {
  display: flex;
  flex-direction: column;
}
.Fields .field-inputs {
  display: flex;
  flex-direction: column;
}
.Fields .field-inputs .Field:not(.tight) {
  margin-bottom: 20px;
}
.Fields .field-inputs .Field .field-label {
  text-transform: none;
  font-weight: 500;
  margin-bottom: 0px;
  color: #3b3b3b;
  font-size: 14px;
  line-height: 18px;
}
.Fields .field-inputs .Field.tight .text-input {
  border-bottom: 1px solid #dcdcdc;
}
.Fields .field-inputs .Field.tight:last-child .text-input {
  border-bottom: 0px;
}
.Fields .field-inputs.group .Field:not(.inline) .field-label {
  margin-bottom: 12px;
}
.Fields .field-inputs.group .Field.inline.inline-choice {
  margin-bottom: 12px;
}
.Fields .field-inputs.group .Field.inline.inline-choice:last-child {
  margin-bottom: 20px;
}
.Fields .field-inputs .field-array-input-group {
  position: relative;
  margin-bottom: 4px;
}
.Fields .field-inputs .field-array-input-group .remove-item {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.Fields .field-inputs .add-item {
  display: inline-grid;
  justify-content: flex-end;
}
.Field {
  position: relative;
}
.Field .error {
  position: absolute;
  right: 8px;
  height: 100%;
  min-height: 20px;
  font-size: 12px;
  color: #ed3512;
  display: flex;
  align-items: center;
  z-index: 1;
  text-transform: lowercase;
}
.Field .text-input {
  background: #f5f5f5;
  padding: 12px 8px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  border-radius: 1px;
}
.Field .text-input::placeholder {
  color: #7e7e7e;
}
.Field .text-input:read-only {
  background: #f9f9f9;
  text-transform: capitalize;
}
.Field .label-and-field .read-only-label {
  display: none;
}
.Field .label-and-field .input-wrapper {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
}
.Field .label-and-field.column .error {
  height: auto;
  top: 36px;
  text-transform: lowercase;
}
.Field.inline {
  margin-bottom: 12px;
}
.Field.inline .label-and-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Field.inline .label-and-field .field-label {
  flex: 1 1 auto;
  margin-right: 8px;
}
.Field.inline .label-and-field .text-input {
  max-width: 115px;
}
.field-label {
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #7e7e7e;
  margin-bottom: 8px;
}
.field-label .requried {
  color: #ed3512;
  font-size: 12px;
}
.field-label .link {
  margin-left: 4px;
}
.field-label.title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}
.FieldsWrapper.read-only {
  margin-bottom: 0px !important;
}
.FieldsWrapper.read-only:last-child {
  border-bottom: 0px;
}
.FieldsWrapper.read-only:last-child > .Field {
  border-bottom: 0px;
}
.FieldsWrapper.read-only .Field {
  border-bottom: 1px solid #f5f5f5;
}
.FieldsWrapper.read-only .Field.inline {
  margin-bottom: 0px !important;
}
.FieldsWrapper.read-only .Field .label-and-field {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding: 8px 0px;
}
.FieldsWrapper.read-only .Field .label-and-field.column {
  grid-template-columns: 1fr;
  grid-gap: 8px;
}
.FieldsWrapper.read-only .Field .label-and-field.column .text-input {
  text-align: left;
}
.FieldsWrapper.read-only .Field .label-and-field.column .Choices {
  justify-content: flex-start;
}
.FieldsWrapper.read-only .Field .label-and-field .field-label {
  display: none;
}
.FieldsWrapper.read-only .Field .label-and-field .read-only-label {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  color: #7e7e7e;
  height: 100%;
  align-items: center;
}
.FieldsWrapper.read-only .Field .label-and-field .text-input {
  border-bottom: 0px;
  padding: 0px;
  text-align: right;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices .label {
  display: none;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices .ChoiceInput {
  pointer-events: none;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices .ChoiceInput:last-child .read-only-label:after {
  display: none;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices .ChoiceInput .label-container {
  margin-right: 0px;
  text-transform: capitalize;
  font-weight: 600;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices .ChoiceInput .read-only-label {
  color: #3b3b3b;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices .ChoiceInput .read-only-label.highlight {
  color: #ed3512;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices .ChoiceInput .read-only-label:after {
  content: ',';
  margin-right: 2px;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices .ChoiceInput .icon-container {
  display: none;
}
.FieldsWrapper.read-only .Field .label-and-field .Choices .ChoiceInput .pseudo-input-container {
  display: none;
}
.FieldsWrapper.read-only .Fields .field-label {
  display: none;
}
.FieldsWrapper.read-only .text-input {
  background: none !important;
  color: #3b3b3b;
}
.FieldsWrapper.read-only .add-item,
.FieldsWrapper.read-only .remove-item {
  display: none;
}
.FieldsWrapper.read-only .group .field-array-input-group {
  margin-bottom: 0px;
}
.FieldsWrapper.read-only .group > :nth-last-child(2).field-array-input-group {
  border-bottom: 0px;
}
.FieldsWrapper.read-only .pseudo-input-container {
  display: none;
}
.FieldsWrapper.read-only .required {
  display: none;
}
.field-groups .Fields {
  margin-bottom: 24px;
}
.FieldsModal .field-label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.FieldsModal .field-label-wrapper .field-label {
  margin-bottom: 0px;
}
.FieldsModal .field-label-wrapper .field-label .required {
  color: #ed3512;
  margin-left: 2px;
}
.FieldsModal .field-label-wrapper .error {
  color: #ed3512;
  margin-left: 4px;
  text-transform: lowercase;
  font-size: 12px;
}
.Form {
  margin-bottom: 0px !important;
}
.Form .form-fields {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.Form .form-fields > .Field {
  margin-bottom: 16px;
}
.Form .form-fields > .Field:last-child {
  margin-bottom: 0px;
}
.Form .form-fields .FieldsWrapper {
  margin-bottom: 24px;
}
.Form .form-fields .FieldsWrapper:last-child {
  margin-bottom: 0px;
}
.Form .form-fields .FieldsWrapper .FieldsModal .button-wrapper {
  padding: 4px 0px 16px;
}
.Form .inner-form-section {
  margin-bottom: 24px;
}
.Form .inner-form-section .section-title .required {
  color: #ed3512;
  margin-left: 4px;
}
.Form .success-message {
  color: #5ad25c;
  font-size: 14px;
  margin: 8px 0;
}
.Form .error-message {
  color: #ed3512;
  font-size: 14px;
  margin: 8px 0;
}
.Form .switch-form {
  margin-top: 16px;
  color: #7e7e7e;
  text-align: center;
  font-size: 14px;
}
.Form .switch-form .link {
  margin-left: 4px;
}
.Form .forgot {
  margin-top: -8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
}
.Select .select__control--is-focused {
  box-shadow: none;
  border-color: none;
}
.Select .select__control--menu-is-open {
  border-color: none;
  cursor: pointer;
}
.Select .select__control:hover {
  border-color: none;
}
.Select .select__control {
  cursor: pointer;
}
.Select .select__placeholder {
  color: none;
}
.Select .select__menu {
  z-index: 3;
}
.Select .select__menu .select__menu-list .select__option {
  cursor: pointer;
}
.Select .select__option:hover,
.Select .select__option.select__option--is-focused {
  background: #f5f5f5;
}
.Select .select__option.select__option--is-selected {
  background: #00529b;
}
.Select .select__control {
  background: #f5f5f5;
  border: 0px;
  border-radius: 1px;
  padding: 4px 0px;
  font-weight: 600;
  font-size: 14px;
  color: #000;
  cursor: pointer;
}
.read-only .Select {
  pointer-events: none;
  text-align: right;
}
.read-only .Select .select__value-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 4px;
  font-weight: 500;
}
.read-only .Select .select__control {
  background: none;
}
.read-only .Select .select__indicators {
  display: none;
}
.appointment-card .date {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #00529b;
}
.appointment-card .date .day-of-month {
  font-size: 28px;
  line-height: 32px;
}
.appointment-card .date .month {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ed3512;
}
.appointment-card .details .name {
  font-size: 20px;
  line-height: 32px;
  color: #00529b;
}
.appointment-card .details .time-and-badge {
  display: flex;
  align-items: center;
}
.appointment-card .details .time-and-badge .time {
  font-size: 14px;
  line-height: 20px;
  color: #7e7e7e;
}
.appointment-card .details .time-and-badge .badge {
  margin-top: 1px;
  margin-left: 8px;
}
.DayPicker {
  display: flex !important;
  justify-content: center;
  margin-bottom: 12px;
}
.DayPicker-Day:focus {
  outline: none;
}
.DayPicker-Day--selected {
  background-color: #00529b !important;
}
.DayPicker-Month {
  margin-top: 0px !important;
}
.no-slots-message {
  text-align: center;
}
.slot-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 80px));
  grid-auto-rows: 32px;
  justify-content: center;
  grid-gap: 12px 16px;
}
.slot-container .slot {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(90,210,92,0.15);
  background: rgba(35,196,140,0.1);
  border-radius: 4px;
  border: 1px solid #23c48c;
  color: #00529b;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}
.slot-container .slot.selected,
.slot-container .slot:hover {
  background: #00529b;
  color: #fff;
  border-color: #fff;
}
.Availability .slot-container .slot.selected,
.Availability .slot-container .slot:hover {
  background: #f00;
}
.DoctorNotes .section-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 8px !important;
}
.DoctorNotes .section-title .title {
  font-size: 16px;
  font-weight: 600;
  color: #00529b;
}
.DoctorNotes .DoctorNotesSection {
  margin-bottom: 32px;
  box-shadow: 0px 0px 10px rgba(3,83,136,0.1);
  padding: 12px;
}
.DoctorNotes .DoctorNotesSection:last-child {
  margin-bottom: 0px;
}
.DoctorNotes .DoctorNotesSection .inner-form-section .section-title {
  display: none;
}
.DoctorNotes .note-wrapper > .Field {
  margin-bottom: 32px;
}
.DoctorNotes .note-wrapper > .Field:last-child {
  margin-bottom: 0px;
}
.DoctorNotesModal .modal-header,
.Modal .modal-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #00529b;
}
.DoctorNotesModal .section-title,
.Modal .section-title {
  margin-bottom: 16px;
}
.DoctorNotesModal .FieldsWrapper,
.Modal .FieldsWrapper {
  margin-bottom: 24px;
}
.DoctorNotesModal .Fields > .field-label,
.Modal .Fields > .field-label {
  display: none;
}
.AppointmentHeader {
  margin-bottom: 24px !important;
}
.CancelAppointment {
  width: 100%;
  margin-top: 12px !important;
}
.cancel-text {
  color: #7e7e7e;
  font-size: 11px;
  line-height: 15px;
  margin-top: 8px;
  text-align: center;
}
.MediaPermissions .section-instructions {
  text-align: center;
}
.MediaPermissions .section-instructions .instruction-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-bottom: 20px;
}
.PreAppointment .cancel-reschedule {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center !important;
}
.PreAppointment .cancel-reschedule .message {
  margin-top: 12px;
}
.PreAppointment .cancel-reschedule .cancel-text {
  margin-top: 8px;
  margin-bottom: 12px;
}
.RecorderInput {
  background: #fff;
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.15);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  margin: 0 8px 8px 0;
}
.RecorderInput .Icon {
  width: 18px;
  color: #008000;
}
.RecorderInput.active .Icon {
  color: #f00;
}
.VideoContainer {
  width: 100%;
  height: 100%;
}
.VideoContainer > div,
.VideoContainer > div .videos-container {
  height: 100%;
}
.VideoContainer .OTSubscriberContainer {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.VideoContainer .OTSubscriberContainer video {
  position: absolute;
  width: 100%;
  height: 100%;
}
.VideoContainer .OTPublisherContainer {
  border: 2px solid #7e7e7e;
  border-radius: 4px;
  position: absolute;
  width: 80px !important;
  height: 120px !important;
  z-index: 2;
  right: 16px;
  top: 16px;
}
.VideoContainer .videos-container {
  background: #000;
}
.VideoContainer .videos-container.notes-open .OTSubscriberContainer {
  position: absolute;
  width: 80px !important;
  height: 120px !important;
  object-fit: cover;
  left: 16px;
  top: 16px;
  border: 2px solid #7e7e7e;
  border-radius: 4px;
}
.VideoContainer .video-bar {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 130px !important;
  background: rgba(255,255,255,0.98);
  bottom: 0;
  border-radius: 30px 30px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  overflow: auto;
}
.VideoContainer .video-bar.open {
  height: 75% !important;
}
.VideoContainer .video-bar .notes-container {
  flex: 1;
  overflow: auto;
  padding: 8px 12px;
}
.VideoContainer .video-bar .video-controls {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 24px 0;
}
.VideoContainer .video-bar .video-controls .video-control-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dcdcdc;
  color: #000;
}
.VideoContainer .video-bar .video-controls .video-control-button:hover {
  cursor: pointer;
  background: #c6c6c6;
}
.VideoContainer .video-bar .video-controls .video-control-button .Icon {
  width: 28px;
  height: 28px;
}
.VideoContainer .video-bar .video-controls .video-control-button.hang-up .Icon,
.VideoContainer .video-bar .video-controls .video-control-button.notes .Icon {
  width: 22px;
  height: 22px;
}
.VideoContainer .video-bar .video-controls .video-control-button.hang-up {
  background: #ed3512;
  color: #fff;
}
.VideoContainer .video-bar .video-controls .video-control-button.hang-up:hover {
  background: #d53010;
}
.VideoContainer .video-bar .video-controls .video-control-button.off {
  background: #7e7e7e;
  color: #fff;
}
.VideoContainer .video-bar .video-controls .video-control-button.off:hover {
  background: #717171;
}
.VideoContainer .video-bar .video-controls .video-control-button.active {
  background: #00529b;
  color: #fff;
}
.VideoContainer .video-bar .video-controls .video-control-button.active:hover {
  background: #004a8b;
}
.WaitingRoom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PaymentForm iframe {
  margin-bottom: 8px;
}
.PaymentForm .form-fields {
  flex: 1;
}
.PaymentForm .error {
  color: #ed3512;
}
.PaymentForm .Choices {
  margin-left: 0px !important;
  flex-wrap: wrap;
}
.PaymentForm .ChoiceInput {
  flex-direction: row-reverse;
  margin-right: 2px !important;
}
.PaymentForm .ChoiceInput .label-container {
  margin-left: 8px !important;
  margin-right: 0px !important;
  text-transform: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #7e7e7e !important;
}
.PaymentForm .ChoiceInput .label-container .link {
  font-size: 14px !important;
}
.PaymentForm .LinkModal {
  margin-left: 2px;
}
.PaymentForm .LinkModal .link {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2px;
}
.PaymentForm .PaymentLineItems {
  margin-bottom: 24px;
}
.PaymentForm .PaymentLineItems hr {
  border-top: 1px solid #7e7e7e;
  margin: 6px 0;
}
.PaymentForm .PaymentLineItems .line-item {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}
.PaymentForm .PaymentLineItems .line-item span {
  font-size: 16px;
  color: #7e7e7e;
  font-weight: 600;
}
.PaymentLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: -24px;
  margin-right: -24px;
  z-index: 999;
}
.Result .result-breakdown {
  text-align: center;
  box-shadow: 0px 0px 10px rgba(3,83,136,0.1);
  border-radius: 4px;
  padding: 16px 20px;
}
.Result .result-breakdown .name {
  color: #7e7e7e;
  font-weight: 700;
}
.Result .result-breakdown .result {
  color: #23c48c;
  font-weight: 700;
  text-transform: uppercase;
}
.Result .result-breakdown .date {
  color: #7e7e7e;
}
.Result .doctors-message {
  margin-top: 24px;
  box-shadow: 0px 0px 10px rgba(3,83,136,0.1);
  border-radius: 4px;
  padding: 16px 20px;
}
.Result .doctors-message .message {
  color: #7e7e7e;
}
.Result .doctors-message .doctors-signature {
  margin-top: 12px;
  text-align: right;
  font-family: 'Euphoria Script', cursive;
  font-size: 20px;
  font-style: italic;
}
.ReviewResults,
.ResultCard,
.select-label {
  margin-bottom: 8px;
}
.select-field {
  margin-bottom: 24px;
}
.Bundle .header {
  margin-bottom: 0px;
}
.Bundle .header .Logo {
  margin-top: 0px;
}
.Bundle .header .section-title {
  margin-bottom: 12px;
}
.Bundle .bundle-steps .bundle-step {
  margin-bottom: 16px;
  display: grid;
  grid-template-areas: "icon title" ". description";
  grid-template-columns: 24px 1fr;
  grid-gap: 4px 12px;
}
.Bundle .bundle-steps .bundle-step:last-child {
  margin-bottom: 0px;
}
.Bundle .bundle-steps .bundle-step .icon-container {
  grid-area: icon;
  display: flex;
  align-items: center;
}
.Bundle .bundle-steps .bundle-step .icon-container .Icon {
  width: 24px;
  height: 24px;
}
.Bundle .bundle-steps .bundle-step .title {
  grid-area: title;
}
.Bundle .bundle-steps .bundle-step .description {
  grid-area: description;
  font-size: 14px;
}
